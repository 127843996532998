<template>
  <div class="xcx-preview">
    <!-- <span class="el-icon-close" @click="$parent.showPreview = false"></span> -->
    <div class="xcx-module-container">
      <!--控件部份-->
      <div v-for="(item, index) in list.controlList" :key="index">
        <!--页面背景-->
        <pagebg :base="item.base"></pagebg>
      </div>

      <div class="xcx-module">
        <!--DIY组件部份-->
        <Module :moduleList="list.moduleList" :prev="true" ref="module" />
      </div>
    </div>

    <div style="height: 80px">
      <!-- 占位符 -->
    </div>

    <NavModule></NavModule>

    <!-- 首页弹框 -->
    <!-- <van-dialog v-model="noticeVisible" show-cancel-button>
      <div
        class="notice-popup-content"
        style="max-height: 80vh; overflow-y: auto"
      >
        <h3 class="notice-title">首页公告</h3>
        <div class="ql-editor" v-html="noticeContent" />
      </div>
    </van-dialog> -->

    <el-dialog destroy-on-close append-to-body :visible.sync="noticeVisible" class="success-dialog el_dialog_radius"
      width="90%">
      <div class="success-dialog-container">
        <div class="title">系统公告</div>

        <div class="dilalog_img">
          <img class="fail" src="@/assets/image/notice_icon.png" />
        </div>

        <div class="ql-editor" v-html="noticeContent" />
        <div class="footer">
          <div class="but" @click="noticeVisible = false">关闭</div>
        </div>
      </div>
    </el-dialog>

    <!-- 微信登录弹框 -->
    <!-- <van-popup
      v-model="WeChatLoginDialog"
      position="bottom"
      round
      :style="{ height: '30%' }"
    >
      <div style="padding: 10px; margin-top: 20px">
        <van-button type="primary" round block @click="WeChatLoginJump()">
          微信登录
        </van-button>
      </div>

      <div style="padding: 10px">
        <van-button round block plain @click="WeChatLoginDialog = false">
          取消
        </van-button>
      </div>
    </van-popup> -->
  </div>
</template>

<script>
import Module from "@/pages/template/mobile/module/module.vue";
import pagebg from "@/pages/template/mobile/module/pagebg.vue";
import { getWebTemplate, getIndexNotice } from "../api/index";
import NavModule from "@/pages/mobile/common/navModule.vue";

import { registerWeChat, getWeChatCode } from "@/api/user";
import { setToken, setUserId } from "@/utils/auth";

import { Toast } from "vant";

export default {
  name: "mindex",
  components: { Module, pagebg, NavModule },
  data() {
    return {
      list: [],
      noticeVisible: false,
      noticeContent: undefined,
      loginForm: {
        weChatCode: undefined,
        platformType: "3",
        inviterCode: undefined,
      },

      weChat_redirect_url: undefined,
      WeChatLoginDialog: false,
    };
  },
  mounted() {
    // this.checkIfWechatBrowser();
  },
  created() {
    //有推广码的话授权
    if (localStorage.getItem("inviterCode")) {
      this.loginForm.inviterCode = localStorage.getItem("inviterCode");
    }

    this.initData();

    //加载首页弹框公告
    this.getNotice();

    //微信登录判断
    this.wxgetMobileCode();
  },
  methods: {
    /**
     *微信登录
     */
    wxgetMobileCode() {
      if (this.$route.query.code) {
        this.loginForm.weChatCode = this.$route.query.code;
        registerWeChat(this.loginForm)
          .then((res) => {
            if (res.status === 0) {
              Toast.success({
                message: "登陆成功",
              });

              const { token, user_id } = res.result;
              setToken(token);
              setUserId(user_id);
              this.$router.push("/");
            }
          })
          .catch((error) => {
            console.error(error);
          })
          .finally(() => {
            // 无论成功失败都会执行的逻辑
          });
      } else {
        var ua = navigator.userAgent.toLowerCase();
        if (ua.match(/MicroMessenger/i) == "micromessenger") {
          this.WeChatLogin();
        }
      }
    },

    WeChatLogin() {
      let data = {
        redirect_url: encodeURIComponent(window.location.href),
      };
      getWeChatCode(data)
        .then((res) => {
          if (res.status === 0 && res.result) {
            window.location.href = res.result;
          }
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          // 无论成功失败都会执行的逻辑
        });
    },



    //初始化页面
    initData() {
      let data = {};
      getWebTemplate(data).then((response) => {
        if (response.status == 0) {
          this.list = response.data;
        }
      });
    },

    /**
     * 查询首页弹框公告
     */
    getNotice() {
      getIndexNotice().then((response) => {
        if (response.result) {
          this.noticeVisible = true;
          this.noticeContent = response.result.content;
          console.log(response);
        }
      });
    },
  },
};
</script>
<style scoped>
.notice-popup-content {
  padding: 20px;
  text-align: center;
}

.notice-title {
  font-size: 18px;
  margin-bottom: 10px;
}

.notice-text {
  color: #999;
  line-height: 32px;
}

.success-dialog-container .el-dialog__body {
  padding: 20px 10px;
}
</style>
